// src/mixins/update.js

import { mapActions } from 'vuex';

export default {
    data() {
      return {
        // refresh variables

      }
    },
    methods: {
        ...mapActions({ 
            clearAlert: 'alert/clear', 
            errorAlert: 'alert/error', 
            successAlert: 'alert/success', 
            showError: 'error/show'
        }),

        clean(obj) {
          for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
              delete obj[propName];
            }
          }
          return obj;
        },

    },
  }