<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 shadow-none mx-auto pt-2 rounded">
        <div class="card shadow">
          <div class="card-body text-center">
            <i
              class="fa fa-file fa-2"
              aria-hidden="true"
            />
            <div class="signin-label mb-4">
              Generate Report
            </div>
            <b-form
              inline
              class="mx-auto justify-content-center"
              @submit.stop.prevent="onSubmit"
            >
              <b-input 
                v-model="$v.form.year.$model" 
                placeholder="Year" 
                size="sm" 
                class="mb-2 mr-sm-2 mb-sm-0"
                :state="validateState('year')"
              />
                                
              <b-form-select 
                v-model="$v.form.month.$model" 
                :options="options" 
                size="sm" 
                class="mr-sm-2 mb-sm-0 col-sm-2 col-xs-12 mb-2"
                :state="validateState('month')"
              />

              <VueBootstrapTypeahead
                v-model="cityQuery"
                size="sm"
                class="mr-sm-2 mb-sm-0 mb-2 col-sm-2 col-xs-12"
                :data="cities"
                :serializer="item => item.name"
                placeholder="Search Code"
                @hit="form.cityId = $event.id; citySelected = $event.name"
              />

              <VueBootstrapTypeahead
                v-model="stateQuery"
                size="sm"
                class="mr-sm-2 mb-sm-0 mb-2 col-sm-2 col-xs-12"
                :data="states"
                :serializer="item => item.name"
                placeholder="Search State"
                @hit="form.stateId = $event.id; stateSelected = $event.name"
              />

              <b-row>
                <b-col sm="12">
                  <b-button
                    pill
                    size="sm"
                    class="ml-2"
                    type="submit"
                    variant="primary"
                    :disabled="isBusy"
                  >
                    Generate
                  </b-button>
                </b-col>
              </b-row>         
            </b-form>

            <b-overlay
              :show="isBusy"
              rounded="sm"
              spinner-type="grow"
              spinner-variant="primary"
            >
              <pre v-if="tariffs">{{ JSON.stringify(tariffs, 0, 2) }}</pre>
            </b-overlay>
          </div> 
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-4">
      <div class="col-md-12 shadow-none mx-auto pt-2 rounded">
        <div
          v-if="items.length"
          class="card shadow text-center"
        >
          <div class="card-body">
            <h4 class="card-title">
              Report Generated (First  10)
            </h4>
            
            <p class="text-center">
              <span v-if="citySelected"><strong>City Selected:</strong> {{ citySelected }} </span>
              <span v-if="stateSelected"><strong>State Selected:</strong> {{ stateSelected }} </span>
            </p>
            <b-btn
              pill
              size="sm"
              class="float-right"
              variant="success"
              @click="onDownload()"
            >
              Download Full Report (XLSX)
            </b-btn>
          </div>
          <b-table
            small
            responsive
            striped
            hover
            :busy="isBusy"
            :fields="fields"
            :items="items"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
import { validationMixin } from "vuelidate";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { required, 
         numeric,
         minLength, 
         maxLength, 
         between 
} from "vuelidate/lib/validators";
import { apiService } from '@/services';
import { mapActions } from 'vuex';

export default {
  name: 'Reports',
  components: {
    VueBootstrapTypeahead
  },
  mixins: [validationMixin, helper],
  data(){
    return {
      cities: [],
      cityQuery: null,
      citySelected: null,
      states: [],
      stateQuery: null,
      stateSelected: null,
      timestamp: null,
      form: {
        year: (new Date().getFullYear()),
        month: (new Date().getMonth()) + 1,
        cityId: null,
        stateId: null
      },
      isBusy: false,
      tariffs: '',
      options: [
        { value: null, text: 'Select Month' },
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' },
      ],
      fields: [
        { key: 'no', class: 'text-center' },
        { key: 'code', class: 'text-center' },
        { key: 'name', class: 'text-center' },
        { key: 'emp_no', class: 'text-center' },
        { key: 'designation', class: 'text-center d-none' },
        { key: 'department', class: 'text-center d-none' },
        { key: 'bill_unit_no', class: 'text-center d-none' },      
        { key: 'code', class: 'text-center d-none' },
        { key: 'qtr_type', class: 'text-center d-none' },
        { key: 'qtr_no', class: 'text-center' },
        { key: 'meter_no_1', class: 'text-center' },
        { key: 'meter_no_1_status', class: 'text-center' },
        { key: 'prev_read_1', class: 'text-center' },
        { key: 'meter_no_2', class: 'text-center' },
        { key: 'meter_no_2_status', class: 'text-center' },
        { key: 'prev_read_2', class: 'text-center' },
        { key: 'present_date', class: 'text-center' },      
        { key: 'pres_read_1', class: 'text-center' },
        { key: 'unit_1', class: 'text-center' },   
        { key: 'pres_read_2', class: 'text-center' },
        { key: 'unit_2', class: 'text-center' },
        { key: 'total_units', class: 'text-center' },
        { key: 'final_amount', class: 'text-center' },
      ],
      items: [],
    }
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    cityQuery(newQuery) {
      if(newQuery.length == 0) {
        this.form.cityId = null;
        this.citySelected = null;
      }
      this.items = [];
      apiService.getAll(`/search/cities?q=${newQuery}`).then((res) => {
        this.cities = res.data
      }).catch(error => {
        this.showError(error);
      });
    },
    stateQuery(newQuery) {
      if(newQuery.length == 0) {
        this.form.stateId = null;
        this.stateSelected = null;
      }
      this.items = [];
      apiService.getAll(`/search/states?q=${newQuery}`).then((res) => {
        this.states = res.data
      }).catch(error => {
        this.showError(error);
      });
    }
  },
  validations: {
    form: {
      year: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      month: {
        required,
        between: between(1,12)
      },
      cityId: {
        numeric
      },
      stateId: {
        numeric
      }
    }
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }else{
        this.isBusy = true;
        this.clean(this.form);
        apiService.postAll('/report/users', this.form).then(response => {
          this.isBusy = false;
          this.items = response.data.items;
          this.timestamp = response.data.timestamp;
          this.isBusy = false;
        }).catch(error => {
          this.isBusy = false;
          this.showError(error);
        });
      }
    },

    onDownload() {
      if (this.items.length<=0) {
        return;
      }else{
        this.isBusy = true;
        this.clean(this.form);
        apiService.getFile('/export/users', this.form).then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Report-${this.timestamp}-.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isBusy = false;
        }).catch(error => {
          this.isBusy = false;
          this.showError(error);
        });
      }
    }
        
  },
    
}
</script>

<style scoped>
.fa-2{ font-size: 4rem; }
</style>